<template>
  <div>
    <div id="data-list-list-view" class="data-list-container mt-2 px-">
      <table id="table-groups">
          <tr class="item-row item">
            <th>{{ $t('Labels.Date') }}</th>
            <th>{{ $t('Labels.Name') }}</th>
            <th>{{ $t('Labels.Description') }}</th>
            <th>{{ $tc('Labels.User',1) }}</th>
            <th></th>
          </tr>
          <tr class="item-row item" v-for="(item, index) in response" :key="index" :data-uuid="item.uuid">
            <listItem
              :value="item.created_at"
              :description="$t('Labels.Date')"
              img="fecha"
              :relativeTime="true"
            />
            <td class="pl-2">
              <avatarItem
                :value="item.group_name || item.tracing_name"
                :description="$t('Labels.Name')"
                :img="item.group_avatar || ''"
              />
            </td>
            <td>
              <listItem
              :value="item.group_description || item.tracing_description"
              :description="$t('Labels.Description')"
            />
            </td>
            <td>
              <listItem
              :value="item.user_email"
              :description="$tc('Labels.User',1)"
            />
            </td>
            <td class="text-right pr-2">
              <b-button
                v-if="item.group_uuid"
                variant="primary"
                class="btn-icon btn-option"
                @click="confirmAccept(item.group_uuid)"
              >
                {{$t('Labels.Accept')}}
              </b-button>
              <b-button
                v-else
                variant="primary"
                class="btn-icon btn-option"
                @click="confirmAcceptTracing(item.tracing_uuid)"
              >
                {{$t('Labels.Accept')}}
              </b-button>
              <b-button
                v-if="item.tracker_user_group_uuid"
                variant="danger"
                class="btn-icon"
                @click="confirmExit(item.tracker_user_group_uuid)"
              >
                {{$t('Labels.Decline')}}
              </b-button>
              <b-button
                v-if="item.tracing_user_tracker_uuid"
                variant="danger"
                class="btn-icon"
                @click="confirmExitConvoy(item.tracing_user_tracker_uuid)"
              >
                {{$t('Labels.Decline')}}
              </b-button>
            </td>
          </tr>
          <tr v-if="response.length == 0" class="noData">
            <td>
              {{$t('Messages.NoRequest')}}
            </td>
          </tr>
      </table>
    </div>
    <b-modal id="modal-confirm" :title="$t('Messages.ConfirmAcceptGroup')"  hide-footer>
      <p>{{$t('Messages.ChooseDeviceAdd')}}</p>
      <b-row>
        <b-col>
          <b-form-select
            name="origin"
            :options="trackers"
            v-model="idTracker"
            value-field="uuid"
            text-field="name"
            :clearable="false"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>{{$tc('Labels.Trackers',1)}}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
      <div class="text-right">
          <b-button variant="primary" @click="acceptRequest()" class="mt-2" :disabled="idTracker === null">
            {{$t('Labels.Accept')}}
          </b-button>
          <b-button variant="danger" @click="$bvModal.hide('modal-confirm')" class="mt-2 ml-1">
            {{$t('Messages.Cancel')}}
          </b-button>
      </div>
    </b-modal>
    <b-modal id="tracing-modal-confirm" :title="$t('Messages.ConfirmAcceptConvoy')"  hide-footer>
      <p>{{$t('Messages.ChooseDeviceAdd')}}</p>
      <b-row>
        <b-col>
          <b-form-select
            name="origin"
            :options="trackers"
            v-model="idTracker"
            value-field="uuid"
            text-field="name"
            :clearable="false"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>{{$tc('Labels.Trackers',1)}}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
      <div class="text-right">
          <b-button variant="primary" @click="acceptConvoyInvitation()" class="mt-2" :disabled="idTracker === null">
            {{$t('Labels.Accept')}}
          </b-button>
          <b-button variant="danger" @click="$bvModal.hide('tracing-modal-confirm')" class="mt-2 ml-1">
            {{$t('Messages.Cancel')}}
          </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import request from '@/libs/request/index'
import avatarItem from '@core/spore-components/avatarItem/avatarItem.vue'
import {
  BButton,
  BModal,
  BFormSelect,
  BFormSelectOption
} from 'bootstrap-vue'
import listItem from '@core/spore-components/listItem/listItem.vue'

const DATA = {
  code: 200,
  data: {
    count: 2,
    rows: [
      {
        uuid: 'cdfbacef-3540-41b5-aae3-448bcd330df4',
        name: 'Familia'
      },
      {
        uuid: 'cdfbacef-3540-41b5-aae3-448bcd330dd3',
        name: 'Amigos'
      },
      {
        uuid: 'cdfbacef-3540-41b5-aae3-448bcd330df5',
        name: 'Amigos 2'
      }
    ]
  },
  msg: 'Ok'
}

export default {
  components: {
    BButton,
    avatarItem,
    listItem,
    BModal,
    BFormSelect,
    BFormSelectOption
  },
  data () {
    return {
      response: [],
      DATA,
      idTracker: null,
      trackers: [],
      idGroup: null,
      idTracing: null
    }
  },

  methods: {
    async getResponse () {
      // this.response = DATA.data.rows
      const params = {
        url: 'tracker_groups/no_confirm',
        method: 'GET'
      }
      await request(params).then(response => {
        this.response = response.data.data
      }).catch(() => {
        this.response = []
      })
    },
    async getTrackers () {
      const params = {
        url: 'tracker/list',
        method: 'GET'
      }
      await request(params).then(response => {
        this.trackers = response.data.data
      }).catch(() => {
        this.trackers = []
      })
    },
    confirmAccept (uuid) {
      this.idGroup = uuid
      this.$bvModal.show('modal-confirm')
    },
    confirmAcceptTracing (uuid) {
      this.idTracing = uuid
      this.$bvModal.show('tracing-modal-confirm')
    },
    async acceptRequest () {
      this.$bvModal.hide('modal-confirm')
      const params = {
        url: `tracker_groups/confirm/${this.idGroup}`,
        method: 'PUT',
        customMessages: true,
        params: {
          tracker_uuid: this.idTracker
        }
      }
      await request(params).then(response => {
        this.getResponse()
        this.$swal({
          title: this.$t('Messages.Success'),
          text: this.$t('Messages.RequestAccepted'),
          timer: 5000,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-success'
          },
          buttonsStyling: false
        })
        this.idGroup = null
        this.idTracker = null
      }).catch(error => {
        if (error.response.data.code === 409) {
          this.$swal({
            title: this.$t('Messages.Fail'),
            text: this.$t('Messages.AlreadyExist'),
            timer: 5000,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
        }
      })
    },
    async acceptConvoyInvitation () {
      this.$bvModal.hide('tracing-modal-confirm')
      const params = {
        url: `tracing/confirm/${this.idTracing}`,
        method: 'PUT',
        customMessages: true,
        params: {
          tracker_uuid: this.idTracker
        }
      }
      await request(params).then(response => {
        this.$swal({
          title: this.$t('Messages.Success'),
          text: this.$t('Messages.RequestAccepted'),
          timer: 5000,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-success'
          },
          buttonsStyling: false
        })
        this.getResponse()
        this.idTracker = null
        this.idTracing = null
      }).catch(error => {
        if (error.response.data.code === 409) {
          this.$swal({
            title: this.$t('Messages.Fail'),
            text: this.$t('Messages.AlreadyExist'),
            timer: 5000,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
        }
      })
    },
    confirmExit (uuid) {
      this.$swal({
        title: this.$t('Messages.DeclineGroup'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Messages.Yes'),
        cancelButtonText: this.$t('Messages.Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `tracker_groups/tracker_group_user/${uuid}`,
            method: 'DELETE',
            customMessages: true
          }
          await request(params).then(response => {
            this.$swal({
              title: this.$t('Messages.Success'),
              text: this.$t('Messages.RequestDeclined'),
              timer: 5000,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success'
              },
              buttonsStyling: false
            })
            this.getResponse()
          })
        }
      })
    },
    confirmExitConvoy (uuid) {
      this.$swal({
        title: this.$t('Messages.DeclineGroup'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Messages.Yes'),
        cancelButtonText: this.$t('Messages.Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `tracing/tracing_tracker/invitation/${uuid}`,
            method: 'DELETE',
            customMessages: true
          }
          await request(params).then(response => {
            this.$swal({
              title: this.$t('Messages.Success'),
              text: this.$t('Messages.RequestDeclined'),
              timer: 5000,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success'
              },
              buttonsStyling: false
            })
            this.getResponse()
          })
        }
      })
    }
  },

  created () {
    this.getResponse()
    this.getTrackers()
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/ListGroups.scss";
</style>
